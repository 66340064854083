import * as React from "react"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import ClientsListBnL from "../../../sections/clients-list-bnl"

const BarsAndLounges = () => (
  <Layout>
    <Seo
      title="Bars &amp; Lounges"
      description="We specialise in F&amp;B, Leisure and Luxury spaces - crafting a complete guest experience through a multidisciplinary approach."
    />
    <section className="category_area pad_btm">
      <div className="category_outer">
        <Container className="p0" fluid>
          <Row className="no-gutters">
            <Col lg={6} xl={7} className="category_image_outer">
              <div className="category_image_inner">
                <div className="category_image">
                  <div className="cat_img">
                    <StaticImage
                      src="../../../images/projects/bnl_hero.jpg"
                      alt="Lounges / Bars"
                      layout="fullWidth"
                    />
                  </div>
                  <div className="cat_title bnl_bg">
                    <h2>
                      Lounges <br />
                      {"//"} Bars
                    </h2>
                  </div>
                  <div className="cat_icon">
                    <StaticImage
                      src="../../../images/projects/bar-lounges-xl-icon.svg"
                      alt="Lounges / Bars icon"
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} xl={5} className="cat_content_bg">
              <div className="cat_content">
                <div className="cat_heading">
                  <h3>Design with a fresh perspective on leisure</h3>
                </div>
                <div className="cat_listing">
                  <div className="cat_lst_itm purple_squr">
                    <Link
                      to="/projects/bars-&amp;-lounges/hard-rock-cafe"
                      className="cta-project"
                    >
                      <h4>Hard Rock Cafe</h4>
                      <p>
                        Bangalore / Guwahati / Hyderabad / Chennai / Colombo /
                        Agra / Delhi
                      </p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm purple_squr">
                    <Link
                      to="/projects/bars-&amp;-lounges/badmaash"
                      className="cta-project"
                    >
                      <h4>Badmaash</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm purple_squr">
                    <Link
                      to="/projects/bars-&amp;-lounges/hangover"
                      className="cta-project"
                    >
                      <h4>Hangover - The Container Bar</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm purple_squr">
                    <Link
                      to="/projects/bars-&amp;-lounges/mirage"
                      className="cta-project"
                    >
                      <h4>Mirage</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/bars-&amp;-lounges#monkey-bar"
                      className="cta-project"
                    >
                      <h4>Monkey Bar</h4>
                      <p>Bangalore / Chennai / Delhi / Kolkata / Mumbai</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/bars-&amp;-lounges#1522"
                      className="cta-project"
                    >
                      <h4>1522</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/bars-&amp;-lounges#never-mind"
                      className="cta-project"
                    >
                      <h4>Never Mind</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/bars-&amp;-lounges#bobs-bar"
                      className="cta-project"
                    >
                      <h4>Bob's Bar</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/bars-&amp;-lounges#conrad"
                      className="cta-project"
                    >
                      <h4>Conrad</h4>
                      <p>Pune</p>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
    <ClientsListBnL />
  </Layout>
)

export default BarsAndLounges
