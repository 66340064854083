import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import YAMLdata from "./clients-list.yaml"

const ClientsListBnL = () => {
  return (
    <section className="clients_area pad_btm">
      <Container className="container">
        <Row>
          <Col>
            <div className="clients_content">
              <h2>{YAMLdata.title}</h2>
              <p>{YAMLdata.content}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="client_logo_list">
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_03.png"
                alt="Badmaash"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_09.png"
                alt="Hard Rock Cafe"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_24.png"
                alt="Hangover"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_26.png"
                alt="Bob's Bar"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_27.png"
                alt="Nevermind Bar &amp; Social"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_29.png"
                alt="Mirage"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_17.png"
                alt="Monkey Bar"
              />
            </div>
          </div>
        </Row>
      </Container>
    </section>
  )
}
export default ClientsListBnL
